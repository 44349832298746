@tailwind base;
@tailwind components;
@tailwind utilities;

.mobile-item {
  margin-bottom: 10px;
}
.mobile-item:last-child {
  margin-bottom: 0;
}
.mobile-image {
  width: 100%;
  max-width: 326px;
  height: 407px;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.desktop-item {
  height: 500px;
}
.desktop-image {
  width: 400px;
  height: 500px;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.desktop-image:hover {
  transform: scale(1.05);
}

.App{
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body .bg-global{
  background-color: #80543F;
}

html, body{
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  /* text-transform: uppercase; */
  /* min-height: 100vh; /* Wysokość minimalna 100% widoku przeglądarki */
  /* display: flex; /* Flexbox, aby elementy dzieci mogły się odpowiednio rozciągać */
  /* flex-direction: column; */
  
  color: #e6e6e6;
  font-family: "Manrope", sans-serif;
  /* background: #5c7c57; */
  
}

.container {
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.section-middle {
  min-width: 0;
}
.contact{
  min-height: 100vh;
}

.content {
  /* margin-top: auto;
  margin-bottom: auto; */
  min-width: 0;
}


p {
  /* font-size: 27px;
  font-weight: 500; */
}
h2 {
  /* font-size: 50px;
  font-weight: 800;
  line-height: 50px; */
  text-transform: uppercase;
}

.top {
  display: block;
  max-width: 100%;
  max-width: 400px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
}

.sticky-picture {
  min-width: 0;
  /* position: fixed; */
  bottom: 0;
  /* width: 100%; */
}

.bottom {
  display: block;
  max-width: 100%;
  max-width: 400px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 0;
}
.navigation-links {
  text-decoration: none;
  color: white;
  text-align: center;
}
a {
  /* margin-left: 15px;
  margin-right: 15px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  margin-top: 50px;
  line-height: 30px; */
}
/* a:visited {
  color: white;
} */
/* a:hover {
  color: #f4c650;
}
a.mail:hover {
  color: #f4c650;
} */
/* a:active {
  color: black;
}
a:link {
  color: white;
} */

.arrow-left, .arrow-right {
  display: flex;
  width: auto; /* USTAWIENIE AUTO, ABY STRZAŁKI NIE PRZESUWAŁY SIĘ */
}

.arrow-left {
  justify-content: flex-start;
}

.arrow-right {
  justify-content: flex-end;
}




/* large grid */
/* .no-underline {
  text-decoration: uppercase;
} */
.paintings-card:hover{
  color:#6193c5;
}

.item, .paintings-card, .image, .title {
  margin: 0;
  padding: 0;
}


@media (max-width: 480px) {
  .top {
    display: block;
    max-width: 100%;
    max-width: 310px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }
  .sticky-picture {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .bottom {
    display: table;
    position: relative;
    margin: auto;
    max-width: 310px;
    height: auto;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  h2 {
    font: 30px;
  }
  a {
    font-size: 12px;
    /* margin-top: 20px;
    margin-bottom: auto; */

    .large-grid > div {
    margin-bottom: 10px;
  }
  .large-grid > div:last-child {
    margin-bottom: 0;
  }
  .large-grid {
    padding-bottom: 80px !important;
  }
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .top {
    max-width: 250px;
  }
  .sticky-picture {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .bottom {
    max-width: 250px;
  }
  p {
    /* font-size: 22px; */ /*dodac klase tam gdzie potrzeba, zwiekszalo font w contact przy zmniejszaniu ekranu*/
  }
  h2 {
    font-size: 40px;
    line-height: 30px;
  }
  a {
    font-size: 14px;
  }
.large-grid {
    padding-bottom: 80px !important;
  }
  
  .tight-lines {
  line-height: 0.8 !important;
  margin-bottom: 0.1rem !important;
}
    
  }

.bg-gray-200 {
  position: relative; /* contains any potential overflow */
  box-shadow: inset 0 0 0 1px #e5e7eb; /* matches gray-200 */
}

/* Anti-aliasing fix */
.flex > div:first-child {
  margin-right: -0.5px;
}

@media (min-width: 768px)  {
  .large-grid {
    display: block;
  }

  body::before {
    content: '';
    position: fixed;
    bottom: -100px;
    left: 0;
    right: 0;
    height: 100vh;
    background: #80543F;
    z-index: -1; /* Behind content */
    pointer-events: none;
  }

}


/* rozdzielczości: 
mobile do 480 - jest,
mobile do 768,
mobile/tablet do 768
tablet do 1024
desktop do 1200
desktop do 1366
desktop do 1920 

zrobic mobile ponizej mniej wiecej 750px
tablet osobno: 768 x 1024 
*/
